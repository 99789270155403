import PageWrapper, { HeroBlock, Divider, CalendlyDropdown, List, ColumnParagraph, ColumnTitle, ColumnTitlePadding, ColumnSection, CenteredText, ColumnLink } from './common.jsx';
import { urls } from './content.jsx';

const PricingPage = (props) => {
    return PageWrapper(<>
        <HeroBlock title='Services and Pricing' />
        { ColumnSection([
            ColumnTitle('How it works'),
            ColumnParagraph(`
To allow for the flexibility to adjust to the plan as your needs change, without
spending time updating contracts and SOWs, I structure my engagements around
fixed-price credits.`),
            ColumnParagraph(`
Each deliverable costs a pre-defined number of credits. At the beginning of the
project, we'll calculate the number of credits you'll need for the
deliverables you expect to want. Then, if your needs change, you can shift credits
to a different set of deliverables.`),
        ], [
            ColumnTitlePadding(),
            ColumnParagraph('Price per credit: $150 (USD)'),
            ColumnParagraph(`
For ongoing projects, I offer discounts for clients who commit to a recurring number
of credits each quarter. You can adjust the number of credits each quarter, if needed.`),
            ColumnParagraph('Discounts for quarterly recurring plans:'),
            List([
                '10% off 60 or more credits/quarter',
                '15% off 100or more credits/quarter',
            ]),
            ColumnParagraph(`
Recurring plans also include six month price amnesty: When I increase the cost
per credit, your recurring plan will be charged at the older (lower) price for the
next two quarters.`),
        ])}
        <Divider />
        {ColumnSection([
            ColumnTitle('Onboarding'),
            ColumnParagraph(`
Each new client must start with an onboarding that allows me to learn about the
product and your target positioning, as well as to develop two to three core stories /
ICP narratives that will become the basis for future content. `),
            ColumnParagraph(`If your positioning is still fuzzy, we'll use
the onboarding to define a plan to clarify it.`),
            ColumnParagraph(`
During the onboarding, I'll interview internal stakeholders and representative
customers/users, as well as  review internal positioning docs and/or transcripts
from customer conversations.`)
        ], [
            ColumnTitlePadding(),
            ColumnParagraph(`
Additional content strategy discussions after onboarding are factored into the
cost of individual content.`),
            ColumnParagraph(`Cost:`),
            List([
                'New client: 30 credits ($4,500)',
                'New product for an existing client: 15 credits ($2,250)',
            ]),
            ColumnParagraph(`
Timeline: Typically takes one month, depending on stakeholder availability. In some cases,
I can start working on content before onboarding is complete.`),
        ])}
        <Divider />
        { ColumnSection([
            ColumnTitle('Short Insight Post'),
            ColumnParagraph(`
Describes one key insight with a short explanation that gets
readers' attention without going into detail. Typically 150-250 words each.`),
            ColumnParagraph(`
Because they're most commonly used for social media posts, it's best to
have a representative of the company (e.g. the CEO) write and post them in their
own voice, based on an outline that I write.`),
        ], [
            ColumnTitlePadding(),
            ColumnParagraph('Cost:'),
            List(['Outline: 1 credit']),
            ColumnParagraph(`
Timeline: I write outlines in batches of 3-10, typically within a week of a request.`),
            ColumnParagraph(
                <ColumnLink text='Example 1' url={ urls.shortInsight1 } newTab={ true } />
            ),
            ColumnParagraph(
                <ColumnLink text='Example 2' url={ urls.shortInsight2 } newTab={ true } />
            ),
        ])}
        <Divider />
        { ColumnSection([
            ColumnTitle('Deep Dive Insight'),
            ColumnParagraph(`
A Deep Dive Insight covers a single insight in more detail than a Short Insight post,
explaining the reasoning and often exploring one key example. Appropriate for a company
blog or newsletter, or an externally published editorial. Typically 500-1000 words.`),
            ColumnParagraph(`
The content is based on conversations with your internal stakeholders, without additional
research.`),
            ColumnParagraph(`
I'll write an outline for you to review and approve, then either I can write the
full post, or you can have someone else write it. If someone else writes the post,
I can provide feedback on a rough draft.`),
        ], [
            ColumnTitlePadding(),
            ColumnParagraph('Cost:'),
            List([
                'Outline: 3 credits',
                'Full text: 8 credits',
                'Feedback: 4 credits',
            ]),
            ColumnParagraph(`
Timeline: I can typically complete outlines within a week and write the full text,
or review the full text, within a week of when you approve the outline or share
the draft. All estimates depend on my backlog.`),
            ColumnParagraph(
                <ColumnLink text='Example 1' url={ urls.deepDive1 } newTab={ true } />
            ),
            ColumnParagraph(
                <ColumnLink text='Example 2' url={ urls.deepDive2 } newTab={ true } />
            ),
        ])}
        <Divider />
        { ColumnSection([
            ColumnTitle('Case study / tutorial / documentation'),
            ColumnParagraph(`
This longer-form piece is a detailed, evergreen resource on a single, clearly scoped
topic that readers can use as a recurring reference. These often require additional
research, creation of figures, development of example code, or interviews with customers.
Typically 1,000-2,500 words.`),
            ColumnParagraph(`
As with the Deep Dive Insight, I'll write an outline for you to review and approve,
then either I can write the full post, or you can have someone else write it. If
someone else writes the post, I can provide feedback on a rough draft.`),
        ], [
            ColumnTitlePadding(),
            ColumnParagraph('Cost:'),
            List([
                'Outline: 6 credits',
                'Full text: 16 credits',
                'Feedback: 8 credits',
            ]),
            ColumnParagraph(`
Timeline: I can typically complete outlines within two weeks after being requested.
Writing the full text may take 2-4 weeks from when you approve the outline.
I can typically  review the full text within a week of when you share the draft.
All estimates depend on my backlog.`)
        ])}
        <Divider />
        { ColumnSection([
            ColumnTitle('White Paper / Lead Magnet'),
            ColumnParagraph(`
An in-depth exploration of a relatively complex topic that readers are expected to
review over time and return to as a reference. Includes custom figures and/or example
code. Often formatted as a PDF rather than on a website, and can be used as a lead magnet.
Typically 3,000-4,000 words (10-14 pages)`),
            ColumnParagraph(
                <ColumnLink text='Example' url={ urls.aiSafePipelines } newTab={ true } />
            ),
        ], [
            ColumnTitlePadding(),
            ColumnParagraph('Cost: Depends on scope and complexity. Typically 40-70 credits ($6-10K)'),
            ColumnParagraph('Timeline: Depends on scope and complexity. Typically 1-3 months'),
        ]) }

        <Divider />
        { CenteredText(`Start growing your trust and credibility with target customers today.`)}
        <CalendlyDropdown />
    </>)
}

export default PricingPage;
