import PageWrapper, { HeroBlock, ColumnLink, Divider, Image, CalendlyDropdown, ColumnParagraph, ColumnTitle, ColumnSection, ColumnTitlePadding, CenteredText } from './common.jsx';

import { extUrls, images } from './content.jsx';

const PortfolioPage = (props) => {
    return PageWrapper(<>
        <HeroBlock title='My Work' />

        <Divider />

        { ColumnSection([
            ColumnTitle('Scaling Biotech'),
            ColumnParagraph(
`I write a weekly newsletter called Scaling Biotech about data
management in early discovery biotech. Despite the esoteric nature of this topic,
the newsletter has over 1,200 subsribers with a consistent open rate over 40%.
I've been accused of hiding microphones in his readers' labs because I often
cover topics that they were just talking about.`),
            ColumnParagraph(<ColumnLink text='Check it out' url={ extUrls.scalingBiotech } newTab={ true } />)
        ], [
            ColumnTitlePadding(),
            (<Image
                url={ extUrls.scalingBiotech }
                image={ images.scalingBiotech }
                newTab={ true }
            />),
        ])}

        <Divider />

        { ColumnSection([
            ColumnTitle('Leading Biotech Data Teams'),
            ColumnParagraph(
`This O'Reilly report, in partnership with Saturn Cloud, proposes a framework called
the Reciprocal Devleopment Principals as an alternative to Agile methodologies
for biotech.`),
            ColumnParagraph(<ColumnLink text='Check it out' url={ extUrls.leadingTeams } newTab={ true } />)
        ], [
            ColumnTitlePadding(),
            (<Image
                url={ extUrls.leadingTeams }
                image={ images.leadingTeams }
                newTab={ true }
            />),
        ])}

        <Divider />

        { ColumnSection([
            ColumnTitle('Building AI-safe Biotech Data Pipelines'),
            ColumnParagraph(
`This collaboration between Merelogic and PTP Cloud explores how the requirements
for data pipelines has changed as a result of AI.`),
            ColumnParagraph(<ColumnLink text='Check it out' url={ extUrls.aiSafePipelines } newTab={ true } />)
        ], [
            ColumnTitlePadding(),
            (<Image
                url={ extUrls.aiSafePipelines }
                image={ images.aiSafe }
                newTab={ true }
            />),
        ])}

        <Divider />

        { ColumnSection([
            ColumnTitle('Unblocking Biotech Data Teams'),
            ColumnParagraph(
`This white paper defines a framework for
designing data infrastructure for early stage discovery biotech in the context of
computational biology, data science and AI/ML teams.`),
            ColumnParagraph(<ColumnLink text='Check it out' url={ extUrls.unblocking } newTab={ true } />)
        ], [
            ColumnTitlePadding(),
            (<Image
                url={ extUrls.unblocking }
                image={ images.unblocking }
                newTab={ true }
            />),
        ])}
        <Divider />

        { CenteredText(`Start growing your trust and credibility with content like this today.`) }
        <CalendlyDropdown />

    </>)

}

export default PortfolioPage;