import PageWrapper, { CalendlyScheduler, CenteredText } from './common.jsx';

const ContactPage = (props) => {
    return PageWrapper(<>
        <div className='centered-text'>
            The best way to reach me is by email: <a href='mailto:jesse@merelogic.net'>jesse@merelogic.net</a>
        </div>

        { CenteredText(`Or use my calendly to schedule a free consultation call:`) }

        <CalendlyScheduler calVis={ true } />
    </>)
}

export default ContactPage;
