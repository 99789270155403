import React, { useState, useEffect } from 'react';
import { RxHamburgerMenu } from "react-icons/rx";

import { images, urls, headerCells, footerLinks } from './content.jsx';

import { GoTriangleRight, GoTriangleDown } from 'react-icons/go';
import { InlineWidget } from "react-calendly";
import { FaArrowRight } from "react-icons/fa6";

import { HashLink as Link } from 'react-router-hash-link';

import axios from "axios";

axios.defaults.headers['Content-Type'] = 'application/json'

const origin = window.location.origin.includes('localhost') ? null : window.location.origin;


const Header = (props) => {
    const [isVisible, setIsVisible] = useState(false);
    const [calVis, setCalVis] = useState(false);

    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };

    return <div className='home-header'>
        <div className='home-header-left'>
            <div className='header-hamburger-wrapper'>
            <div className='header-hamburger' onClick={ toggleVisibility }><RxHamburgerMenu /></div>
                <a href={urls.home}>
                    <div className='logo-cell'>
                        <img width='200' src={ images.merelogicLogo.image } alt={ images.merelogicLogo.alt } />
                    </div>
                </a>
            </div>
            <div className='home-header-cells-wrapper' style={ isVisible ? { display: 'block' } : null }>
                <div className='home-header-cells'>
                    { headerCells.map((c, i) =>
                        <Link to={ c.url } key={ i }>
                            <div className='home-header-cell'>{ c.display }</div>
                        </Link>)}
                </div>
            </div>
        </div>
        <div className='home-header-right'>
            <div className='home-button' onClick={() => { setCalVis(!calVis) }}>
                Schedule a free consultation
            </div>
            <div className='header-cal'>
                <CalendlyScheduler calVis={ calVis } />
            </div>
        </div>
    </div>
}

const Footer = (props) => {
    return <div className='home-footer'>
        <a href={urls.home}>
            <div className='logo-cell'>
                <img width='200' src={ images.merelogicLogo.image } alt={ images.merelogicLogo.alt } />
            </div>
        </a>
        <div className='home-footer-links'>
            { footerLinks.map((l, i) => (
                <a href={ l.url } key={ i }><div className='home-footer-link'>{ l.display }</div></a>
            ))}
        </div>
    </div>
}

export const HeroBlock = (props) => {
    return <div>
        <div>
            <div className='hero-inner'>
                <div className='hero-title'>
                    { props.title }
                </div>
                { props.subtitle ? (<>
                <div className='hero-subtitle'>
                    { props.subtitle }
                </div>
                <CalendlyDropdown />
                </>) : null }
            </div>
        </div>
    </div>
}

export const CalendlyScheduler = (props) => {
    return <div className={ props.calVis ? '' : 'hidden'}>
        <InlineWidget url="https://calendly.com/merelogic/free-consultation-call" />
    </div>
}

export const CalendlyDropdown = (props) => {
    const [calVis, setCalVis] = useState(false);

    return <div>
        <div className='consult-call' onClick={() => { setCalVis(!calVis)}}>
            {
                calVis ?
                <GoTriangleDown size={32} style={{ verticalAlign: "middle" }}/> :
                <GoTriangleRight size={32} style={{ verticalAlign: "middle" }}/>
            }
            Schedule a free consultation
        </div>
        <CalendlyScheduler calVis={ calVis } />
    </div>
}

export const Divider = (props) => {
    const width=120;
    const height=1.8;
    return <div className='divider'  id={ props.id ? props.id : null }>
        <svg width={width * 4} height={height * 2} viewBox="0 0 10 5">
            <ellipse cx="0" cy={height} rx={width} ry={height} fill="black" />
        </svg>
    </div>
}

export const ColumnLink = (props) => {
    return <a href={props.url}  target={ props.newTab ? '_blank' : null } rel="noreferrer">
        {props.text} <FaArrowRight size={24} style={{ verticalAlign: "middle" }} />
    </a>
}

export const Image = (props) => {
    if (props.url) {
        return <div className='column-image-div'>
            <a href={ props.url } target={ props.newTab ? '_blank' : null } rel="noreferrer">
                <img className='column-image' src={ props.image.image } alt={ props.image.alt } />
            </a>
        </div>
    } else {
        return <img className='column-image' src={ props.image.image } alt={ props.image.alt } />

    }
}

export const ColumnSection = (left, right, id) => {
    return <div className='section-outer' id={ id ? id : null }>
        <div className='two-column'>
            <div className='two-column-inner'>
                { left }
            </div>
            <div className='two-column-inner'>
                { right }
            </div>
        </div>
    </div>
}

export const ColumnTitle = (title) => {
    return <div className='column-title'>{ title }</div>
}

export const ColumnTitlePadding = () => {
    return <div className='column-title-padding' />
}

export const ColumnSubtitle = (title) => {
    return <div className='column-subtitle'>{title}</div>

}

export const ColumnParagraph = (text) => {
        return <div className='column-body'>
            { text }
        </div>
}

export const List = (items, cl) => {
    return ColumnParagraph(
        <ul>
            {items.map((i, j) => <li key={ j } className={ cl ? cl : null }>{ i }</li>)}
        </ul>
    )
}

export const CenteredText = (text) => {
    return <div className='centered-text'>
        { text }
    </div>
}

const setSessionId = (id) => {
    sessionStorage.setItem('sessionId', id);
}

function getSession() {
    const sessionData = JSON.stringify({
        userAgent: navigator.userAgent,
        language: navigator.language,
        doNotTrack: navigator.doNotTrack,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        referrer: document.referrer,
        currentUrl: window.location.href,
        connectionType: navigator.connection ? navigator.connection.effectiveType : 'unknown',
    });

    axios
        .post(`${origin}/homepage_logging/session/`, {session_data: sessionData})
        .then((result) => {
            setSessionId(result.data.id);
            logPage(result.data.id);
        })
}

const logPage = (sessionId) => {
    axios.post(`${origin}/homepage_logging/event/`, {
        url: window.location.href,
        session: sessionId,
        timestamp: new Date().toISOString(),
    });
}

const logAction = () => {
    if (!origin) { return; }
    var sessionId = sessionStorage.getItem('sessionId');

    if (!sessionId) {
        setSessionId('tmp');
        if (origin) {
            getSession();
        }
    } else if (sessionId !== 'tmp') {
        logPage(sessionId);
    }
}

const PageWrapper = (contents) => {
    useEffect( () => logAction());

    return <div className='home-outer'>
        <div className='home-limiter'>
            <Header />
            { contents }
            <div className='horizontal-buffer' />
            <Footer />
            <div className='bottom-buffer'> </div>
        </div>
    </div>

}

export default PageWrapper;
