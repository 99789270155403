import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import PricingPage from './pricing.jsx';
import PortfolioPage from './portfolio.jsx';
import ContactPage from './contact.jsx';

import PageWrapper, { HeroBlock, Divider, ColumnLink, CalendlyDropdown, List, ColumnParagraph, ColumnTitle, ColumnTitlePadding, ColumnSubtitle, ColumnSection, CenteredText } from './common.jsx';

import { images, urls } from './content.jsx';

import './index.css';

const HomePagePage = (props) => {
    return PageWrapper(<>
        <HeroBlock
            title='Trust and credibility for the age of AI'
            subtitle='Turning B2B SaaS companies into authoritative sources of industry knowledge and solutions'
        />
        <Divider id='whoiam'/>
        { ColumnSection([
            ColumnTitle('Who I am'),
            ColumnParagraph(`
I've been building engaged, loyal audiences with my writing about data, machine learning
and AI since 2013, while working in engineering and leadership roles at companies from
Google to biotech startups.`),
            ColumnParagraph(`
As a decision maker in multiple technical organization, I learned first-hand how
teams identify and evaluate software tools. And by writing for other decision makers,
I developed a style and a methodology that allows me to do more than just communicate
information.`),
            ColumnParagraph(`
I know how to generate trust and goodwill with audiences struggling to
understand a rapidly changing world. My goal is to help B2B SaaS companies do the same.`),
        ], [
            ColumnTitlePadding(),
            ColumnParagraph(
                <div className='portrait-wrapper'>
                    <img width='300' src={ images.jesse.image } alt={ images.jesse.alt } />
                    <div>Jesse Johnson</div>
                    <div>Content Strategist & Writer</div>
                </div>
            )
        ]) }
        <Divider id='whatibelieve' />
        { ColumnSection([
            ColumnTitle('What I Believe'),
            ColumnParagraph(`
Organizations fail to adopt truly transformative software because they lack the
necessary framing and don't yet trust the newest and most effective tools.`),
            ColumnParagraph(`
Yes, AI-generated drivel is flooding the internet. But as decision makers become
more skeptical of low-quality content and more reliant on socially validated
information sources, the bar for content is actually rising.`),
        ], [
            ColumnTitlePadding(),
            ColumnSubtitle('The market is looking for:'),
            List([
                'Content that sounds like it was written by a (human) expert',
                'Genuinely valuable insights that readers are excited to share',
                'Strong opinions and clear stances',
            ], 'wide')
        ]) }
        <Divider id='whoiserve' />
        { ColumnSection([
            ColumnTitle('Who I Serve'),
            ColumnParagraph(`My clients are B2B SaaS companies who:`),
            List([
                'Develop cutting edge software for emerging technical niches',
                'Have found product-market fit and are ready to scale',
                'Recognize trust and authority as central to their marketing strategy',
            ], 'wide')
        ], [
            ColumnTitlePadding(),
            ColumnParagraph(`
Trust-focused marketing is not for everyone. It may not make sense for large account-based
sales, or established markets where customers only care about features.`),
            ColumnParagraph(`
But if your go-to-market strategy requires long-term relationships with small- to
medium-sized businesses in an emerging or transforming sector, I may be able to help.`)
        ])
        }
        <Divider />
        { ColumnSection([
            ColumnTitle('What I Offer'),
            ColumnParagraph(`
I help clients identify and communicate the fundamental knowledge, intuition and beliefs
that have shaped how they designed their software, and how they believe it will help
their customers.`),
            ColumnParagraph(`
Sharing these insights publicly, so that others can benefit from your hard-earned
knowledge, creates goodwill that fuels trust and credibility.`)
        ], [
            ColumnTitlePadding(),
            ColumnSubtitle('Content that digs deep'),
            List([
                'Marketing strategy and positioning',
                'Social, Newsletter and Blog posts',
                'White papers, case studies and lead magnets',
            ], 'wide'),
            ColumnParagraph(
                <ColumnLink text='See my full list of services' url={ urls.pricing } />
            )
        ]) }
        <Divider />
        { ColumnSection([
            ColumnTitle('My Work'),
            ColumnParagraph(`
I create content that unpacks complex topics and clearly defined perspectives in a
form that appeals to technical decision makers.`)
        ], [
            ColumnTitlePadding(),
            ColumnSubtitle('See for yourself'),
            ColumnParagraph(
                <ColumnLink text='My portfolio' url={ urls.portfolio } />
            )
        ]) }
        <Divider />
        { CenteredText(`Don't wait another day to start growing your trust and
        credibility with target customers`) }
        <CalendlyDropdown />
    </>)
}

const root = ReactDOM.createRoot(document.getElementById('root'));

const NotFound = (props) => {
    return PageWrapper(<>
        <HeroBlock
            title='Page not Found'
        />
        { CenteredText(
            <ColumnLink text='Return to the Merelogic homepage' url={ urls.home } />
        )}
    </>)
}

const Noai = (props) => {
    return PageWrapper(<>
        { ColumnSection([
            ColumnTitle(`Narrative Optimization for AI Report`),
            ColumnParagraph(`
I'm no longer offering free or paid reports on how AI chatbots answer questions about B2B software.`),
ColumnParagraph(`
The more research I've done about how AI chatbots answer these kinds of questions, the more I realize I don't know enough to confidently offer the products/services. I'm continuing to monitor the space, and maybe one day I'll try again. But today isn't that day.`),
        ], [
            ColumnTitlePadding(),
            ColumnTitlePadding(),
            ColumnParagraph(`
Instead, I'm back to focusing on an area that I know how to help B2B SaaS companies: Creating marketing content that builds trust and credibility with your target customers.`),
            ColumnParagraph(<ColumnLink text='Learn more here' url={ urls.home } />)
        ])}
    </>)
}

root.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path={'/'} element={<HomePagePage />} />
                <Route path={'pricing'} element={<PricingPage />} />
                <Route path={'portfolio'} element={<PortfolioPage />} />
                <Route path={'contact'} element={<ContactPage />} />
                <Route path={'report_card'} element={<Noai />} />
                <Route path={'noai'} element={<Noai />} />
                <Route path={'*'} element={<NotFound />} />
            </Routes>
        </Router>
    </React.StrictMode>
);
